<template>
  <div class="query_wrap">
    <h1 class="h_none">商标查询</h1>
    <h2 class="h_none">蓝灯鱼商标查询,蓝灯鱼商标检索,商标,商标查询,商标检索</h2>
    <img src="/images/result_show_logo.png" alt="" class="h_none">
    <!-- <headers></headers> -->
    <div class="query040" :class="{'have-result':queryMode}">
      <!--<h3 class="tiaoshi3">{{queryMode}}*{{firstChoosed}}</h3>-->
      <!-- <pubHeader001></pubHeader001> -->
      <!--      <div class="status_wrap" v-show="!queryMode">-->
      <!--        <div class="status-box">-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="Search_title">
        <p>商标查询</p>
        <p>现购买任一国家（中国除外），送相同期限中国查询！本产品支持在线应用，API接口调用请联系我们</p>
      </div>

      <div class="pay_main">
        <!--    数据库-->
        <el-card  class="box-cards" >
          <!--    数据库-->
          <div class="index_1">
            <el-checkbox  @change="database" v-model="checked">数据库</el-checkbox>
            <!--          <div>-->
            <!--            <el-button @click="selectAll" type="primary" size="mini" >全部选择</el-button>-->
            <!--            <el-button @click="cancelAll" type="primary" size="mini" >全部取消</el-button>-->
            <!--          </div>-->
          </div>

          <!--      亚洲-->
          <div style="display: flex;margin-top: 10px">
            <div>
              <el-checkbox style="width: 100px" :indeterminate="asiaIsIndeterminate" v-model="asiaCheckAll" @change="handleCheckAllChange">亚洲  ({{index1}}/14)</el-checkbox>
            </div>
            <div style="margin-left: 32px">
              <el-checkbox-group class="checkbox_group" v-model="asia" @change="handleCheckedCitiesChange">
                <el-checkbox :disabled="item.code == 'CN'" class="checkbox" v-for="(item,index) in asiaCity"  :label="item.code" :key="item.city">
                  <img class="checkbox_img" :src="item.ingSrc" alt="">
                  <span style="margin-left: 12px">{{item.city}}</span>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>

          <!--      欧洲-->
          <div style="display: flex;margin-top: 10px">
            <div>
              <el-checkbox style="width: 100px" :indeterminate="europeIsIndeterminate" v-model="europeCheckAll" @change="handleCheckAllChanges">欧洲  ({{index2}}/19)</el-checkbox>
            </div>
            <div style="margin-left: 32px">
              <el-checkbox-group class="checkbox_group" v-model="europe" @change="handleCheckedCitiesChanges">
                <el-checkbox class="checkbox" v-for="(item,index) in europeCity"  :label="item.code" :key="item.city">
                  <img class="checkbox_img" :src="item.ingSrc" alt="">
                  <span style="margin-left: 12px">{{item.city}}</span>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>

          <!--      北美洲-->
          <div style="display: flex;margin-top: 10px">
            <div >
              <el-checkbox style="width: 100px" :indeterminate="americaIsIndeterminate" v-model="americaCheckAll" @change="handleCheckAllChangess">北美洲  ({{index3}}/3)</el-checkbox>
            </div>
            <div style="margin-left: 32px">
              <el-checkbox-group class="checkbox_group" v-model="america" @change="handleCheckedCitiesChangess">
                <el-checkbox class="checkbox" v-for="(item,index) in americaCity"  :label="item.code" :key="item.city">
                  <img class="checkbox_img" :src="item.ingSrc" alt="">
                  <span style="margin-left: 12px">{{item.city}}</span>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>

          <!--      南美洲-->
          <div style="display: flex;margin-top: 10px">
            <div >
              <el-checkbox style="width: 100px" :indeterminate="southIsIndeterminate" v-model="southCheckAll" @change="handleCheckAllChangesss">南美洲  ({{index4}}/3)</el-checkbox>
            </div>
            <div style="margin-left: 32px">
              <el-checkbox-group class="checkbox_group" v-model="south" @change="handleCheckedCitiesChangesss">
                <el-checkbox class="checkbox" v-for="(item,index) in southCity"  :label="item.code" :key="item.city">
                  <img class="checkbox_img" :src="item.ingSrc" alt="">
                  <span style="margin-left: 12px">{{item.city}}</span>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>

          <!--      非洲-->
          <div style="display: flex;margin-top: 10px">
            <div >
              <el-checkbox style="width: 100px" :indeterminate="africaIsIndeterminate" v-model="africaCheckAll" @change="handleCheckAllChangessss">非洲  ({{index5}}/1)</el-checkbox>
            </div>
            <div style="margin-left: 32px">
              <el-checkbox-group class="checkbox_group" v-model="africa" @change="handleCheckedCitiesChangessss">
                <el-checkbox class="checkbox" v-for="(item,index) in africaCity"  :label="item.code" :key="item.city">
                  <img class="checkbox_img" :src="item.ingSrc" alt="">
                  <span style="margin-left: 12px">{{item.city}}</span>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>

          <!--      大洋洲-->
          <div style="display: flex;margin-top: 10px">
            <div >
              <el-checkbox style="width: 100px" :indeterminate="oceaniaIsIndeterminate" v-model="oceaniaCheckAll" @change="handleCheckAllChangesssss">大洋洲  ({{index6}}/2)</el-checkbox>
            </div>
            <div style="margin-left: 32px">
              <el-checkbox-group class="checkbox_group" v-model="oceania" @change="handleCheckedCitiesChangesssss">
                <el-checkbox class="checkbox" v-for="(item,index) in OceaniaCity"  :label="item.code" :key="item.city">
                  <img class="checkbox_img" :src="item.ingSrc" alt="">
                  <span style="margin-left: 12px">{{item.city}}</span>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>

          <!--        知识产权组织-->
          <div style="display: flex;margin-top: 10px">
            <div >
              <el-checkbox style="width: 100px" :indeterminate="propertyIndeterminate" v-model="propertyCheckAll" @change="handleCheckAllChangessssse">知识产权组织  ({{index7}}/1)</el-checkbox>
            </div>
            <div style="margin-left: 50px">
              <el-checkbox-group class="checkbox_group" v-model="property" @change="handleCheckedCitiesChangessssse">
                <el-checkbox class="checkbox" v-for="(item,index) in propertyOrganizationCity"  :label="item.code" :key="item.city">
                  <img class="checkbox_img" :src="item.ingSrc" alt="">
                  <span style="margin-left: 12px">{{item.city}}</span>
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>


          <div style="position: absolute;right: 40px;bottom: 40px;">
            <el-button  @click="selectAll" type="primary" size="mini" >全部选择</el-button>
            <el-button  @click="cancelAll" type="primary" size="mini" >全部取消</el-button>
          </div>


        </el-card>

        <!--      周期-->
        <div class="cycle">
          <div class="block">
            <span class="demonstration">周期</span>
            <el-slider
              class="cycle_content"
              @input="cycle"
              v-model="value2"
              :step="50"
              :show-tooltip="false"
              :marks="marks"
              show-stops>
            </el-slider>
          </div>
        </div>

        <!--      支付价格-->
        <div class="price">
          <div class="price_cen">
            <div class="amount">
              <p>价格总计: <span style="margin-left: 15px">{{getPrice}}</span>&nbsp;元</p>
              <!--              <img v-if="discountFlag == 1"  style="height: 18px;position: absolute" src="/images/index/active.png" alt="">-->
              <span v-if="discountFlag == 1"  class="zhe">5折</span>
            </div>
            <div class="order_btn">
              <el-button v-if='stateChange == "CN"'  disabled :class="['el-order-btn','active']" >立即购买</el-button>
              <el-button v-else :class="['el-order-btn']" @click="payEvent">立即购买</el-button>
            </div>
          </div>
        </div>
      </div>


    </div>
    <footers></footers>
  </div>

</template>

<script>
  import { customizedOrder,priceList } from "@/request/trademarkPayment";
  import { mapState, mapMutations, mapGetters } from 'vuex'
  import login from "@/components/common/login"
  import footers from '@/components/common/xfooter'
  // 亚洲
  const cityOptions = [
    {
      city:'中国大陆',
      code:'CN',
      ingSrc:'/images/national/zgdl.jpg'
    },
    {
      city:'中国香港',
      code:'HK',
      ingSrc:'/images/national/zgxg.jpg'
    },
    {
      city:'印度尼西亚',
      code:'ID',
      ingSrc:'/images/national/ydnxy.jpg'
    },
    {
      city:'印度',
      code:'IN',
      ingSrc:'/images/national/yd.jpg'
    },
    {
      city:'日本',
      code:'JP',
      ingSrc:'/images/national/rb.jpg'
    },
    {
      city:'土耳其',
      code:'TR',
      ingSrc:'/images/national/trq.jpg'
    },
    {
      city:'韩国',
      code:'KR',
      ingSrc:'/images/national/hg.jpg'
    },
    {
      city:'中国澳门',
      code:'MO',
      ingSrc:'/images/national/am.jpg'
    },
    {
      city:'马来西亚',
      code:'MY',
      ingSrc:'/images/national/mlxy.jpg'
    },
    {
      city:'菲律宾',
      code:'PH',
      ingSrc:'/images/national/flb.jpg'
    },
    {
      city:'新加坡',
      code:'SG',
      ingSrc:'/images/national/xjp.jpg'
    },
    {
      city:'中国台湾',
      code:'TW',
      ingSrc:'/images/national/zgtw.png'
    },
    {
      city:'泰国',
      code:'TH',
      ingSrc:'/images/national/tgs.jpg'
    },
    {
      city:'阿拉伯联合酋长国',
      code:'AE',
      ingSrc:'/images/national/alq.jpg'
    }
  ]
  // 欧洲
  const city = [
    {
      city:'奥地利',
      code:'AT',
      ingSrc:'/images/national/adl.JPG'
    },
    {
      city:'瑞士',
      code:'CH',
      ingSrc:'/images/national/rs.jpg'
    },
    {
      city:'德国',
      code:'DE',
      ingSrc:'/images/national/dg.jpg'
    },
    {
      city:'丹麦',
      code:'DK',
      ingSrc:'/images/national/dm.jpg'
    },
    {
      city:'挪威',
      code: 'NO',
      ingSrc:'/images/national/nw.jpg'
    },
    {
      city:'西班牙',
      code:'ES',
      ingSrc:'/images/national/xby.jpg'
    },
    {
      city:'欧盟',
      code:'EU',
      ingSrc:'/images/national/om.jpg'
    },
    {
      city:'芬兰',
      code:'FI',
      ingSrc:'/images/national/fl.jpg'
    },
    {
      city:'法国',
      code:'FR',
      ingSrc:'/images/national/fg.jpg'
    },
    {
      city:'英国',
      code:'GB',
      ingSrc:'/images/national/yg.jpg'
    },
    {
      city:'葡萄牙',
      code:'PT',
      ingSrc:'/images/national/pty.jpg'
    },
    {
      city:'希腊',
      code:'GR',
      ingSrc:'/images/national/xl.jpg'
    },
    {
      city:'匈牙利',
      code:'HU',
      ingSrc:'/images/national/xyl.jpg'
    },
    {
      city:'爱尔兰',
      code:'IE',
      ingSrc:'/images/national/ael.jpg'
    },
    {
      city:'冰岛',
      code:'IS',
      ingSrc:'/images/national/bd.jpg'
    },
    {
      city:'意大利',
      code:'IT',
      ingSrc:'/images/national/ydl.jpg'
    },
    {
      city:'瑞典',
      code:'SE',
      ingSrc:'/images/national/rd.jpg'
    },
    {
      city:'俄罗斯',
      code:'RU',
      ingSrc:'/images/national/elss.jpg'
    },
    {
      city:'比荷卢经济联盟',
      code:'BX',
      ingSrc:'/images/national/bhl.png'
    }
  ]
  // 北美洲
  const cityOptionsd = [
    {
      city:'加拿大',
      code:'CA',
      ingSrc:'/images/national/jnd.jpg'
    },
    {
      city:'墨西哥',
      code:'MX',
      ingSrc:'/images/national/mxg.jpg'
    },
    {
      city:'美国',
      code:'US',
      ingSrc:'/images/national/mg.jpg'
    },
  ]
  // 南美洲
  const cityOptionsds = [
    {
      city:'阿根廷',
      code:'AR',
      ingSrc:'/images/national/agt.jpg'
    },
    {
      city:'巴西',
      code:'BR',
      ingSrc:'/images/national/bx.jpg'
    },
    {
      city:'智利',
      code:'CL',
      ingSrc:'/images/national/zl.jpg'
    },
  ]
  // 非洲
  const cityOpts = [
    {
      city:'南非',
      code:'ZA',
      ingSrc:'/images/national/nf.jpg'
    }
  ]
  // 大洋洲
  const cityds = [
    {
      city:'澳大利亚',
      code:'AU',
      ingSrc:'/images/national/adly.jpg'
    },
    {
      city:'新西兰',
      code:'NZ',
      ingSrc:'/images/national/xxl.jpg'
    }
  ]
  //知识产权组织
  const propertyOrganization = [
    {
      city:'世界知识产权组织国际局',
      code:'WO',
      ingSrc:'/images/national/zscq.jpg'
    }
  ]

  export default {
    name: "query",

    metaInfo: {
      title: '蓝灯鱼 商标查询', // set a title
      meta: [{                 // set meta
        name: 'keyWords',
        content: '蓝灯鱼商标查询,蓝灯鱼商标检索,商标,商标查询,商标检索,图片识别,商标数据,近似商标,中国商标,商标名,商标分类,商标图片,商标文字,商标图片查询api'
      }, {                 // set meta
        name: 'description',
        content: '基于全球商标图片，按照维也纳编码分类，对图片进行特征提取和机器学习，通过"以图搜图"AI智能方式查询，准确查找出近似图片。同时，支持商标文字查询,商标档案查看等'
      }],
    },
    data () {
      return {
        //价格是否优惠
        discountFlag:0,
        numberPeriod:['42000','180000','600000','1200000','500000'],
        //总和数据库
        allDatabase:[],
        price:0,
        //价格单
        priceList:[],
        //进度条
        marks: {
          0: '一年',
          50: '二年',
          100: '三年',
        },
        servicePeriod:['一年','二年','三年'],
        servicePeriods:['yearPrice','twoYearPrice','threeYearPrice'],
        // activityHalfYearPrice: 1500
        // activityMonthPrice: 500
        // activityQuarterPrice: 1000
        // activityWeekPrice: 250
        // activityYearPrice: 2500
        serviceActivePeriod:['activityYearPrice','activityTwoYearPrice','activityThreeYearPrice'],
        //默认周期
        value2: 0,
        //数据库
        boxShow:false,
        //是否旋转
        isRotate:true,
        //数据库全选
        checked: false,
        //亚洲
        asiaCheckAll: false,
        asiaIsIndeterminate: true,
        // 欧洲
        europeCheckAll: false,
        europeIsIndeterminate: false,
        // 北美洲
        americaCheckAll: false,
        americaIsIndeterminate: false,
        // 南美洲
        southCheckAll: false,
        southIsIndeterminate: false,
        // 非洲
        africaCheckAll: false,
        africaIsIndeterminate: false,
        // 大洋洲
        oceaniaCheckAll: false,
        oceaniaIsIndeterminate: false,
        //世界知识产权组织
        propertyCheckAll: false,
        propertyIndeterminate: false,
        //亚洲
        asia: ['CN'],
        asiaCity: cityOptions,
        // 欧洲
        europe: [],
        europeCity: city,
        // 北美洲
        america: [],
        americaCity: cityOptionsd,
        // 南美洲
        south: [],
        southCity: cityOptionsds,
        // 非洲
        africa: [],
        africaCity: cityOpts,
        // 大洋洲
        oceania: [],
        OceaniaCity: cityds,
        //知识产权组织
        property: [],
        propertyOrganizationCity:propertyOrganization,
        dialogFormVisible:true,
        dialogClose: false,
        /*动画相关*/
        searchBoxAppear: true,
        inputFocus: false,
        btnHover: false,
        riverHhorseAppear: false,
        /*查询方式*/
        queryMode: "",//image,text
        /*查询条件*/
        firstList: [
          { text: "申请人精确", param: 2, queryWay: "term", classControl: "" },
          { text: "申请号精确", param: 3, queryWay: "term", classControl: "" },
          { text: "名称近似", param: 1, queryWay: "", classControl: "" },
          { text: "申请人近似", param: 2, queryWay: "", classControl: "" },
          { text: "图形近似", param: "", queryWay: "", classControl: "" },
          { text: "申请号近似", param: 3, queryWay: "", classControl: "" },
          { text: "代理公司", param: 4, queryWay: "", classControl: "" },
        ],//param,queryWay爲後臺需要,classControl爲類名控制需要;
        firstChoosed: "",
        /*筛选条件*/
        niceList: [],//尼斯分类
        tmStatusList: [
          { name: "商标已注册", isCheck: false, code: 1 },
          { name: "商标申请中", isCheck: false, code: 2 },
          { name: "初审公告中", isCheck: false, code: 3 },
          { name: "驳回复审中", isCheck: false, code: 4 },
          { name: "商标异议中", isCheck: false, code: 5 },
          { name: "商标无效", isCheck: false, code: 6 },
          { name: "其他情形", isCheck: false, code: 7 }
        ],//法律状态
        appDateList: [],//申请日期
        moreObj: {
          category: false,
          status: false,
          year: false,
        },
        searchText: "",
        pageNo: 1,//当前页数
        pageSize: 20,//每页查询多少条
        total: 0,
        pagesTotal: 0,
        base64Url: "",//需要查询的文件
        imageCodeList: "",//图形编码
        disabledBool: false,
        param: "",//默认为空，  1为商标名称查询/2为申请人查询/3为申请号查询/4为代理公司查询
        selectedArea: "",//selectedArea
        /*结果*/
        // resultList: [2],
        resultList: [],
        switchStyle: false,
        // 用户选择的默认显示形式  表格或图文
        switchStyleDefault: false,
        dialogVisible: false,
        serviceNameType: "",
        resultBool: false,
        countryList: [{
          value: 'CN',
          label: '中国大陆'
        }, {
          value: 'EU',
          label: '欧盟'
        }, {
          value: 'US',
          label: '美国'
        }],
        countryVal: 'CN',
        tag: ''
      }
    },
    components: {
      login,
      footers
      //   headers
    },
    created () {
      for (let i = 1; i <= 45; i++) {
        this.niceList.push({
          name: i < 10 ? "0" + i : i, categoryNo: i < 10 ? "0" + i : i, isCheck: false,
        })
      }
    },
    mounted () {
      //中文数据库总和
      this.allDatabase = this.allDatabase.concat(this.asiaCity,this.europeCity,this.americaCity,this.southCity,this.africaCity,this.OceaniaCity,this.propertyOrganizationCity)
      //服务价格表
      priceList({
        "priceType": 'tm_query',
        "currency": 'CNY'
      }).then((res) => {
        if(res.code == 0) this.priceList = res.data
      })
      if (this.$route.query.source == 'index' && datafromIndex.queryMode) {
        this.searchText = datafromIndex.text;
        this.base64Url = datafromIndex.image;
        this.searchFirstFn();
      }
      this.riverHhorseAppear = true;
      if (!this.tool.getCookie("auth")) {
      } else {
        // this.searchServiceAjax();
      }
    },
    destroyed () {
      $(window).unbind('scroll', this.scrollFn);
    },
    methods: {
      ...mapMutations(['setMe', 'setNoticeList']),
      //获取选择的周期
      cycle(){

      },
      //立即购买
      payEvent(){
        if(this.price == 0){
          this.$message({
            message: '请选择您要购买的服务',
            type: 'warning'
          });
        }else {
          let data = []
          this.stateChange.split(';').map(item => {
            this.allDatabase.map(items => {
              if(items.code == item){
                data.push(items.city)
              }
            })
          })
          localStorage.setItem('orderId','')
          localStorage.setItem('allDatabase',data.join('；'))
          localStorage.setItem('price', this.getPrice)
          localStorage.setItem('keyword',  this.stateChange)
          // localStorage.setItem('servicePeriod', this.servicePeriod.indexOf(this.marks[this.value2]) + 1)
          this.$router.push({
            path:'/trademarkApply',
            query:{
              trademark:true,
              modeType: 1,
              servicePeriod: this.servicePeriod.indexOf(this.marks[this.value2]) + 5,
              discountFlag: this.discountFlag
            }
          })
        }
      },
      //数据库
      database(value){
        if(value){
          this.selectAll()
        }else {
          this.cancelAll()
        }
      },
      //点击显示数据库
      rotate(){
        this.isRotate = !this.isRotate
        this.boxShow = !this.isRotate
      },

      //全部选中
      selectAll(){
        //数据库
        this.checked = true
        //亚洲
        this.asiaCheckAll = true
        this.handleCheckAllChange(true)
        // 欧洲
        this.europeCheckAll = true
        this.handleCheckAllChanges(true)
        // 北美洲
        this.americaCheckAll = true
        this.handleCheckAllChangess(true)
        // 南美洲
        this.southCheckAll = true
        this.handleCheckAllChangesss(true)
        // 非洲
        this.africaCheckAll = true
        this.handleCheckAllChangessss(true)
        // 大洋洲
        this.oceaniaCheckAll = true
        this.handleCheckAllChangesssss(true)
        //世界知识产权
        this.propertyCheckAll = true
        this.handleCheckAllChangessssse(true)
      },
      //全部取消
      cancelAll(){
        //数据库
        this.checked = false
        //亚洲
        this.asiaCheckAll = true
        this.handleCheckAllChange(false)
        // 欧洲
        this.europeCheckAll = false
        this.handleCheckAllChanges(false)
        // 北美洲
        this.americaCheckAll = false
        this.handleCheckAllChangess(false)
        // 南美洲
        this.southCheckAll = false
        this.handleCheckAllChangesss(false)
        // 非洲
        this.africaCheckAll = false
        this.handleCheckAllChangessss(false)
        // 大洋洲
        this.oceaniaCheckAll = false
        this.handleCheckAllChangesssss(false)
        // 世界知识产权
        this.propertyCheckAll = false
        this.handleCheckAllChangessssse(false)
      },

      //商标支付
      apply(){
        this.$router.push({path:'/trademarkPayment'})
      },
      //-------------------------------------------------选中------------------------------------------
      handleCheckAllChange(val) {
        this.asia = val ?  cityOptions.map(item => item.code) : ['CN']
        this.asiaIsIndeterminate = true;
      },
      handleCheckAllChanges(val) {
        this.europe = val ? city.map(item => item.code) : [];
        this.europeIsIndeterminate = false;
      },
      handleCheckAllChangess(val) {
        this.america = val ? cityOptionsd.map(item => item.code) : [];
        this.americaIsIndeterminate = false;
      },
      handleCheckAllChangesss(val) {
        this.south = val ? cityOptionsds.map(item => item.code) : [];
        this.southIsIndeterminate = false;
      },
      handleCheckAllChangessss(val) {
        this.africa = val ?  cityOpts.map(item => item.code) : [];
        this.africaIsIndeterminate = false;
      },
      handleCheckAllChangesssss(val) {
        this.oceania = val ? cityds.map(item => item.code) : [];
        this.oceaniaIsIndeterminate = false;
      },
      //世界知识产权组织
      handleCheckAllChangessssse(val) {
        this.property = val ? propertyOrganization.map(item => item.code) : [];
        this.propertyIndeterminate = false;
      },
      //亚洲
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.asiaCheckAll = checkedCount === this.asiaCity.length;
        this.asiaIsIndeterminate = checkedCount > 0 && checkedCount < this.asiaCity.length;
      },
      // 欧洲
      handleCheckedCitiesChanges(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.europeCheckAll = checkedCount === this.europeCity.length;
        this.europeIsIndeterminate = checkedCount > 0 && checkedCount < this.europeCity.length;
      },
      // 北美洲
      handleCheckedCitiesChangess(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.americaCheckAll = checkedCount === this.americaCity.length;
        this.americaIsIndeterminate = checkedCount > 0 && checkedCount < this.americaCity.length;
      },
      //南美洲
      handleCheckedCitiesChangesss(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.southCheckAll = checkedCount === this.southCity.length;
        this.southIsIndeterminate = checkedCount > 0 && checkedCount < this.southCity.length;
      },
      //非洲
      handleCheckedCitiesChangessss(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.africaCheckAll = checkedCount === this.africaCity.length;
        this.africaIsIndeterminate = checkedCount > 0 && checkedCount < this.africaCity.length;
      },
      //大洋洲
      handleCheckedCitiesChangesssss(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.oceaniaCheckAll = checkedCount === this.OceaniaCity.length;
        this.oceaniaIsIndeterminate = checkedCount > 0 && checkedCount < this.OceaniaCity.length;
      },
      //世界知识产权组织
      handleCheckedCitiesChangessssse(value) {
        //-----------------------------------
        let checkedCount = value.length;
        this.propertyCheckAll = checkedCount === this.propertyOrganizationCity.length;
        this.propertyIndeterminate = checkedCount > 0 && checkedCount < this.propertyOrganizationCity.length;
      },
      //  查询蓝灯鱼当前登录用户的服务列表
      async searchServiceAjax () {
        let data = await this.$axios.get("/lantern/serviceLan/searchService");
        if (data.data.msg == "success") {
          if (data.data.data) {
            this.setTrialList(data.data.data)
            this.init();
          }
        }
      },
      // 去申请
      toApplyEvent () {
        if (!this.tool.getCookie("auth")) {
          this.dialogVisible = true;
        } else {
          this.$router.push({
            path: "/account/trialInfo",
            query: {
              serverType: 3
            }
          })
        }
      },
      closeEvent (done) {
        this.dialogClose = true;
        done();
      },
      dialogCloseBool (data) {
        this.dialogClose = data;
      },
      init () {
        if (this.trialList) {
          for (var i = 0; i < this.trialList.length; i++) {
            if (this.trialList[i].serviceName == 0) {
              this.serviceNameType = this.trialList[i].serviceType
            }
          }
        }
      },
      // 登录弹层隐藏
      loginDialog (data) {
        this.dialogVisible = data;
        // this.init();
        //   if (!this.unLoginBool  && this.serviceNameType == 2) {
        //     this.$router.push({
        //       path: "/account/trialInfo",
        //       query: {
        //         serverType: 2
        //       }
        //     })
        //   }
      },
      blobToDataURL (blob, callback) {
        var a = new FileReader();
        a.onload = function (e) {
          callback(e.target.result);
        };
        a.readAsDataURL(blob);
      },
      queryDataUrl (data) {
        if (data && data.includes("data:image")) return data;
        else if (data && !data.includes("http")) return "https://tmimage.lanternfish.cn" + data;
        else return "";
      },
      searchFirstFn () {
        if(this.tool.getCookie("auth")){

        }else {
          this.dialogVisible = true
          return;
        }
        if (!this.queryMode) {
          this.switchStyleDefault = false;
        } else {
          this.switchStyleDefault = true;
        }
        this.searchFn();
      },
      confirmSizer () {
        this.switchStyleDefault = true;
        this.searchFn();
      },
      resetSizer () {
        this.niceList.map((item) => {
          item.isCheck = false;
        });
        this.tmStatusList.map((item) => {
          item.isCheck = false;
        });
      },
      firstChange (index) {
        let bolBefore = this.firstList[this.firstChoosed].text.includes("图形");
        this.firstChoosed = index;
        let bolAfter = this.firstList[this.firstChoosed].text.includes("图形");
        if (bolAfter) this.queryMode = "image";
        else {
          this.queryMode = "text";
          this.base64Url = "";
        }
        if (!bolBefore && !bolAfter) this.searchFirstFn();
      },
      moreFn (who) {
        this.moreObj[who] = !this.moreObj[who];
      },
      searchFn () {
        if (this.searchText) {
          if (!this.queryMode) {
            let regex = new RegExp("；", "g"),
              regexSemi = new RegExp("^[;]+|[;]+$", "g");
            let searchTextArr = this.searchText.replace(regex, ";").replace(regexSemi, "").split(";");
            let isAppNo = new RegExp(/^[gGaA]?[0-9]+[gGaA]?$/);
            if (searchTextArr.length != 0) {
              for (var i = 0; i < searchTextArr.length; i++) {
                if (!isAppNo.test(searchTextArr[i])) {
                  this.firstChoosed = 2;
                  this.pageNo = 1;
                  // this.queryMode = "text";
                  // this.querySearch_FromDB();
                  this.getJurisdictionAjax();
                  return;
                } else {
                  this.firstChoosed = 1;
                }
              }
            } else {
              if (isAppNo.test(this.searchText)) {
                this.firstChoosed = 1;
              } else {
                this.firstChoosed = 2;
              }
            }
          }
          this.pageNo = 1;
          // this.queryMode = "text";
          // this.querySearch_FromDB();
          this.getJurisdictionAjax();
        } else if (this.base64Url) {
          this.pageNo = 1;
          // this.queryMode = "image";
          // this.graphSearch_FromDB()
          this.getJurisdictionAjaxImage();
        } else {
          this.$message("请输入文字或者上传图片");
        }

        /* this.resultList = [
           {level: '高'},
           {level: '中'},
           {level: '低'},
         ];*/
      },
      goDetail (item) {
        // $(window).unbind('scroll', this.scrollFn);
        window.open('/query/detail' + '?id=' + item.id + '&country=' + item.country);
        /*        this.$router.push({
                  path: "/queryDetail",
                  query: {
                    id: item.id || 852
                  }
                })*/
      },
      aLargerPreview (item) {
        window.open(this.queryDataUrl(item.imageUrl));
        this.resultList.map(item => item.showBig = false);
        item.showBig = true;
      },
      scrollFn () {
        let scrollTop = $(window).scrollTop();    //滚动条距离顶部的高度
        let scrollHeight = $(document).height();   //当前页面的总高度
        let clientHeight = $(window).height();    //当前可视的页面高度
        if (scrollTop + clientHeight + 170 >= scrollHeight) {   //距离顶部+当前高度 >=文档总高度 即代表滑动到底部
          //滚动条到达底部
          let numMax = this.pagesTotal;
          let num = this.pageNo;
          if (num < numMax && this.resultList.length) {//this.resultMarks.length防止
            num++;
            this.loadMore = "加载中......";
            this.handleCurrentChange(num);
          } else {
            this.loadMore = "加载完毕";
          }
          $(window).unbind('scroll', this.scrollFn);
        } else if (scrollTop <= 0) {
          //滚动条到达顶部
          // console.log(4);
          //滚动条距离顶部的高度小于等于0 TODO
        }
      },
      handleCurrentChange (val) {
        this.pageNo = val;
        this.switchStyleDefault = true;
        switch (this.queryMode) {
          case "image": {
            //   this.queryMode = "image";
            //   this.graphSearch_FromDB()
            this.getJurisdictionAjaxImage();
          }
            break;
          case "text": {
            //   this.querySearch_FromDB();
            this.getJurisdictionAjax();
          }
            break;
        }
      },
      // 更新是否是第一次使用服务的状态
      async updateIsFirstClickAjax () {
        let data = await this.$axios.put("/lantern/serviceLan/updateIsFirstClick", {
          params: {
            serviceName: 0
          }
        })
      },
      // 验证蓝灯鱼用户或者未登陆用户是否还有检索次数
      async getJurisdictionAjax () {
        let data = await this.$axios.get("/lantern/trademarkSearchLan/getJurisdiction");
        if (data.data.code == 0) {
          this.queryMode = "text";
          this.querySearch_FromDB();
          this.$axios.put("/lantern/trademarkSearchLan/updateCount")
        } else if (data.data.code == 1) {
          if (data.data.data.isLogin == 'false') {
            //   if (data.data.data.count == "100") {
            //     this.$confirm(this.$t('patentSearch.confirmTitle2'), this.$t('patentSearch.tips'), {
            //       confirmButtonText: this.$t('patentSearch.goLogin'),
            //       cancelButtonText: this.$t('patentSearch.cancelBtn'),
            //       type: 'warning'
            //     }).then(() => {
            //       this.dialogVisible = true;
            //     }).catch(() => {
            //     })
            //   } else {
            this.setMe('');
            this.setNoticeList('');
            this.$message({
              message: data.data.msg
            });
            return;
            //   }
          } else if (data.data.data.isLogin == 'true') {
            if (data.data.data.firstClick == 'true') {
              this.$confirm(this.$t('patentSearch.confirmTitle3'), this.$t('patentSearch.tips'), {
                confirmButtonText: this.$t('patentSearch.apply'),
                cancelButtonText: this.$t('patentSearch.cancelBtn'),
                type: 'warning'
              }).then(() => {
                this.updateIsFirstClickAjax();
                if (data.data.data.isApply == 'false') {
                  this.$message({
                    message: "您已经提交申请，审核通过后，会给您发送通知"
                  });
                } else {
                  this.$router.push({
                    path: "/account/trialInfo",
                    query: {
                      serverType: 3
                    }
                  })
                }
              }).catch(() => {
                this.updateIsFirstClickAjax();
              });
            } else if (data.data.data.firstClick == 'false') {
              if (data.data.data.count == "100") {
                this.$confirm(this.$t('patentSearch.confirmTitle1'), this.$t('patentSearch.tips'), {
                  confirmButtonText: this.$t('patentSearch.apply'),
                  cancelButtonText: this.$t('patentSearch.cancelBtn'),
                  type: 'warning'
                }).then(() => {
                  if (data.data.data.isApply == 'false') {
                    this.$message({
                      message: "您已经提交申请，审核通过后，会给您发送通知"
                    });
                  } else {
                    this.$router.push({
                      path: "/account/trialInfo",
                      query: {
                        serverType: 3
                      }
                    })
                  }
                }).catch(() => {
                })
              } else {
                this.$message({
                  message: data.data.msg
                });
              }
            }
          }
        }
      },
      /*文字查詢*/
      querySearch_FromDB () {
        if (!this.searchText) return;
        //   this.$nuxt.$loading.start()
        this.queryMode = "text";
        this.$axios.post('/common/query/search', {
          current: this.pageNo,
          keywork: this.searchText,
          size: this.pageSize,
          queryWay: this.firstChoosed !== "" ? this.firstList[this.firstChoosed].queryWay : undefined,
          niceList: this.niceChecked || undefined,
          param: this.firstChoosed !== "" ? this.firstList[this.firstChoosed].param : undefined,
          appDate: "",
          tmStatus: this.tmStatusChecked || undefined,
          country: this.countryVal,
          lang: "zh"
        }).then(({ code, data, msg }) => {
          // this.$nuxt.$loading.finish()
          if (data.code == 1) {
            this.$confirm('您的查询次数已用完', this.$t(`patentSearch.tips`), {
              confirmButtonText: '去购买',
              cancelButtonText: this.$t(`patentSearch.cancelBtn`),
              center: true,
              customClass: "time_confirm",
              closeOnClickModal: false
            }).then(() => {
              this.$router.push({
                path: "/personalCenter/productService",
                query: {
                  serverId: 27
                }
              })
            }).catch(() => {
            })
          } else {
            this.searchHandle(data.data);
          }
        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish()
            console.log(error);
          })
      },
      // 验证蓝灯鱼用户或者未登陆用户是否还有检索次数
      async getJurisdictionAjaxImage (params) {
        let data = await this.$axios.get("/lantern/trademarkSearchLan/getJurisdiction");
        if (data.data.code == 0) {
          this.queryMode = "image";
          this.graphSearch_FromDB()
          this.$axios.put("/lantern/trademarkSearchLan/updateCount");
        } else if (data.data.code == 1) {
          if (data.data.data.isLogin == 'false') {
            //   if (data.data.data.count == "100") {
            //     this.$confirm(this.$t('patentSearch.confirmTitle2'), this.$t('patentSearch.tips'), {
            //       confirmButtonText: this.$t('patentSearch.goLogin'),
            //       cancelButtonText: this.$t('patentSearch.cancelBtn'),
            //       type: 'warning'
            //     }).then(() => {
            //       this.dialogVisible = true;
            //     }).catch(() => {
            //     })
            //   } else {
            this.setMe('');
            this.setNoticeList('');
            this.$message({
              message: data.data.msg
            });
            return;
            //   }
          } else if (data.data.data.isLogin == 'true') {
            if (data.data.data.firstClick == 'true') {
              this.$confirm(this.$t('patentSearch.confirmTitle3'), this.$t('patentSearch.tips'), {
                confirmButtonText: this.$t('patentSearch.apply'),
                cancelButtonText: this.$t('patentSearch.cancelBtn'),
                type: 'warning'
              }).then(() => {
                this.updateIsFirstClickAjax();
                if (data.data.data.isApply == 'false') {
                  this.$message({
                    message: "您已经提交申请，审核通过后，会给您发送通知"
                  });
                } else {
                  this.$router.push({
                    path: "/account/trialInfo",
                    query: {
                      serverType: 3
                    }
                  })
                }
              }).catch(() => {
                this.updateIsFirstClickAjax();
              });
            } else if (data.data.data.firstClick == 'false') {
              if (data.data.data.count == "100") {
                this.$confirm(this.$t('patentSearch.confirmTitle1'), this.$t('patentSearch.tips'), {
                  confirmButtonText: this.$t('patentSearch.apply'),
                  cancelButtonText: this.$t('patentSearch.cancelBtn'),
                  type: 'warning'
                }).then(() => {
                  if (data.data.data.isApply == 'false') {
                    this.$message({
                      message: "您已经提交申请，审核通过后，会给您发送通知"
                    });
                  } else {
                    this.$router.push({
                      path: "/account/trialInfo",
                      query: {
                        serverType: 3
                      }
                    })
                  }
                }).catch(() => {
                })
              } else {
                this.$message({
                  message: data.data.msg
                });
              }
            }
          }
        }
      },
      /*图片查询*/
      graphSearch_FromDB () {
        if (!this.base64Url) return;
        //   this.$nuxt.$loading.start()
        this.queryMode = "image";
        this.firstChoosed = 4;
        this.$axios.post('/common/graph/search', {
          current: this.pageNo,
          size: this.pageSize,
          base64File: this.base64Url.replace(/^data:image\/\w+;base64,/, ""),
          imageCodeList: this.imageCodeList || undefined,
          niceList: this.niceChecked || undefined,
          param: undefined,
          selectedArea: this.selectedArea || undefined,
          appDate: "",
          tmStatus: this.tmStatusChecked || undefined,
          country: this.countryVal,
          lang: "zh"
        }).then(({ code, data, msg }) => {
          // this.$nuxt.$loading.finish()
          if (data.code == 1) {
            this.$confirm('您的查询次数已用完', this.$t('patentSearch.tips'), {
              confirmButtonText: '去购买',
              cancelButtonText: this.$t('patentSearch.cancelBtn'),
              center: true,
              customClass: "time_confirm",
              closeOnClickModal: false
            }).then(() => {
              this.$router.push({
                path: "/personalCenter/productService",
                query: {
                  serverId: 27
                }
              })
            }).catch(() => {
            })
          } else {
            this.searchHandle(data.data);
          }
        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish()
            console.log(error);
          })
      },
      /*文字或图片查询的后续处理*/
      searchHandle (data) {
        if (this.pageNo == 1) {
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
        }
        this.resultBool = true;
        if (this.switchStyleDefault == false) {
          if (this.queryMode == "image") {
            this.switchStyle = true;
          } else if (this.queryMode == "text") {
            this.switchStyle = false;
          }
        }

        $(window).unbind('scroll', this.scrollFn);
        $(window).scroll(this.scrollFn);
        data.records.map(item => {
          item.showName = item.tmapplicant || item.tmapplicant_en || item.applicant_en || item.applicantEn;
          item.showAdress = item.tmaddress || item.tmaddress_en || item.address_en || item.addressEn;
          // item.showTmdetail = item.tmdetail || item.tmdetail_en || item.tmdetailEn;
          item.showTrademarkName = (item.tmcn && item.tmen ? item.tmcn + "--" + item.tmen : item.tmcn || item.tmen);


          // 服务平台图片近似查询结果评分标准：
          // score<=85 高，
          // score>85 and score<=250 中，
          // score>250 低，

          // 服务平台文字近似查询结果评分标准：
          // score>=80 高，
          // score>=20 and score<80 中，
          // score<20 低


          let level = "";
          if (this.queryMode == 'image') {
            if (parseInt(item.score) <= 85) level = '高';
            else if (parseInt(item.score) > 85 && parseInt(item.score) <= 250) level = '中';
            else if (parseInt(item.score) > 250) level = '低';
          } else if (this.queryMode == 'text') {
            if (parseInt(item.score) >= 80) level = '高';
            else if (parseInt(item.score) >= 20 && parseInt(item.score) < 80) level = '中';
            else if (parseInt(item.score) < 20) level = '低';
          }
          item.level = level;

          if (item.type != 'tm_info') {
            try {
              if (item.tmdetailEn.indexOf("niceCode\\\\\":") != -1) {
                item.tmdetailEn = item.tmdetailEn.replaceAll("niceCode\\\\\":", "niceCode\\\\\"");
              }
              item.tmdetail = JSON.parse(item.tmdetailEn || "[]");
              item.tmdetail = item.tmdetail.map(item2 => `${item2.niceCode}:${item2.value}`).join(";");
            } catch (e) {
              item.tmdetail = item.tmdetail_en;
            }
          }
          item.showTmdetail = item.tmdetail || item.tmdetail_en || item.tmdetailEn;
        });
        //22864036,
        this.resultList = this.pageNo == 1 ? data.records : this.resultList.concat(data.records);
        this.$set(this.resultList, 'checked', false)
        this.total = data.total;
        this.pagesTotal = data.pages;
      },
      inputFn () {
        if (this.firstChoosed == 4) this.firstChoosed = 2;
        if (this.firstChoosed === "") this.firstChoosed = 2;
      },
      handleRemove () {
        this.base64Url = "";
        this.$refs.upload.clearFiles();
      },
      uploadChangeFn (file, fileList) {
        if (fileList.length > 1) {
          fileList.splice(0, 1);
        }
        this.blobToDataURL(file.raw, (base64) => {
          this.base64Url = base64;
          this.pageNo = 1;
          this.firstChoosed = 4;
          this.searchText = "";
          // this.queryMode = "image";
          // this.graphSearch_FromDB()
          this.getJurisdictionAjaxImage();
        });
        return;
        this.upload_({ file: file.raw })
          .then(({ code, data, msg }) => {
            if (code) {
              this.$message(msg);
              return;
            }
            this.base64Url = data;
            this.getJurisdictionAjaxImage();
          })
      },
      // 下载
      downEvent () {
        //   this.$nuxt.$loading.start()
        this.$axios.post('common/query/exportExcel', {
          keywork: this.searchText,
          queryWay: this.firstChoosed !== "" ? this.firstList[this.firstChoosed].queryWay : undefined,
          niceList: this.niceChecked || undefined,
          param: this.firstChoosed !== "" ? this.firstList[this.firstChoosed].param : undefined,
          appDate: "",
          tmStatus: this.tmStatusChecked || undefined,
          country: this.countryVal
        }, {
          responseType: 'blob'
        }).then(({ code, data, msg }) => {
          // this.$nuxt.$loading.finish()
          const content = data
          const blob = new Blob([content], {
            type: "application/vnd.ms-excel;charset=utf-8"
          });
          const fileName = '查询结果.xls'
          if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else { // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
          .catch((error) => {
            //   this.$nuxt.$loading.finish()
            console.log(error);
          })
      }
    },
    computed: {
      ...mapState(['me', 'trialList']),
      index1(){
        return this.asia.length
      },
      index2(){
        return this.europe.length
      },
      index3(){
        return this.america.length
      },
      index4(){
        return this.south.length
      },
      index5(){
        return this.africa.length
      },
      index6(){
        return this.oceania.length
      },
      index7(){
        return this.property.length
      },
      niceChecked () {
        return this.niceList.filter(item => item.isCheck).map(item2 => item2.categoryNo).join(";");
      },
      tmStatusChecked () {
        return this.tmStatusList.filter(item => item.isCheck).map(item2 => item2.code).join(";");
      },
      //国家个数发生改变
      stateChange(){
        return this.asia.concat(this.europe,this.america,this.south,this.africa,this.oceania,this.property).join(';')
      },
      //获取价格
      getPrice(){
        this.price = 0
        if(this.stateChange == "") return this.price
        let number  = this.stateChange.split(';').length == 1 ? this.stateChange.split(';').length : this.stateChange.split(';').length -1
        this.priceList.filter(item => {
          if(item.specNum ==  number){
            this.discountFlag = item[this.serviceActivePeriod[this.servicePeriod.indexOf(this.marks[this.value2])]] ? 1 : 0
            this.price = item[this.serviceActivePeriod[this.servicePeriod.indexOf(this.marks[this.value2])]] ? item[this.serviceActivePeriod[this.servicePeriod.indexOf(this.marks[this.value2])]] : item[this.servicePeriods[this.servicePeriod.indexOf(this.marks[this.value2])]]
            return item
          }
        })
        return this.price
      }
    },
    watch: {
      $route () {
        this.tag = this.$route.query.tag
      },
      tag (val) {
        if (val == 1 || val == undefined) {
          this.$router.go(0);
        }
      },
      queryMode (val) {
        if (val != "") {
          this.tag = 2;
        }
      }
    }
  }
</script>
<style>
  @keyframes riverHorse_query {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      transform: translate3d(0, -380px, 0);
    }

    60% {
      opacity: 1;
      transform: translate3d(0, 8px, 0);
    }

    75% {
      opacity: 1;
      transform: translate3d(0, -20px, 0);
    }

    90% {
      opacity: 1;
      transform: translate3d(0, 5px, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes searchInputAppear_query {
    from {
      width: 0;
    }
    to {
      width: 960px;
    }
  }

  @keyframes camera_query {
    from {
      font-size: 0px;
    }
    to {
      font-size: 38px;
    }
  }

  @keyframes cameraImg {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
</style>
<style lang="less" scoped>
  .box-cards{
    /deep/.is-checked{
      visibility: visible!important;
    }

    /deep/.el-checkbox__input{
      /*visibility: hidden;*/
    }

    /deep/.el-checkbox__label{
      font-size: 12px;
    }

    /deep/.el-card__body{
      padding: 28px 0px 0px 37px;
      box-sizing: border-box;
    }

    /deep/.is-indeterminate{
      visibility: visible!important;
    }

    /*position: absolute;*/
    /*top: 37%;*/
    /*left: 50%;*/
    /*width: 1200px;*/

    position: relative;
    margin: 0 auto;
    /*margin-left: -590px;*/
    .index_1{
      display: flex;
      justify-content: space-between;
    }
    .checkbox_group{
      display: flex;
      /*justify-content: space-between;*/
      flex-wrap: wrap;
      flex: 1;
      .checkbox {
        width: 142px;
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        margin-right: 12px!important;
        .checkbox_img {
          width: 30px;
          height: 20px;
        }

        /deep/.el-checkbox__label{
          display: flex;
        }
      }
    }
  }

  .cycle{
    margin: 40px auto 80px;
    /*width: 1200px;*/
    padding-left: 30px;
    .cycle_content{
      width: 434px;
      /deep/.el-slider__marks-text{
        width: 42px;
        font-size: 14px;
        color: rgb( 51, 51, 51 );
      }
      /deep/.el-slider__runway{
        height: 12px;
        background: #dce4fe;
        /deep/.el-slider__bar{
          height: 12px!important;
        }
        /deep/.el-slider__button-wrapper {
          top: -13px!important;
        }
      }
      /deep/.el-slider__stop {
        height: 12px;
        width: 12px;
      }
    }
  }

  .price{
    /*width: 1200px;*/
    margin: 0 auto;
    text-align: right;
    .price_cen{
      display: inline-block;
      width: 500px;
      text-align: right;
      position: relative;
      .amount{
        position: absolute;
        top: -25px;
        right: 0;
        /*width: 900px;*/
        text-align: right;
        display: inline-block;
        font-size: 18px;
        color: rgb( 51, 51, 51 );
        font-weight: bold;
        padding-left: 15px;
        span{
          font-size: 34px;
          color: rgb( 255, 106, 55 );
        }

        .zhe{
          margin-right: 3px;
          display: table-cell;
          width: 40px;
          height: 20px;
          background: #ff6a37;
          color: white;
          border-radius: 5px;
          font-size: 14px;
          vertical-align:middle;
          text-align:center;
          margin-left: 8px;
        }
        display: flex;

      }
      .order_btn {
        position: absolute;
        text-align: right;
        display: inline-block;
        /*width: 900px;*/
        margin: 26px auto 0;
        position: relative;
        align-items: flex-end;
        .el-order-btn {
          margin-left: 10px;
          width: 144px;
          height: 52px;
          background: @blue;
          font-size: 20px;
          color: rgb( 255, 255, 255 );
          line-height: 57px;
          border-radius: 3px;
          padding: 0;
          border: none;
          &:hover {
            box-shadow: @boxShadow;
          }
          &.active{
            opacity: 0.5;
          }
          &.disabled {
            background-color: #d9d9dc;
            cursor: default;
            &:hover {
              box-shadow: none;
            }
          }
        }
      }
    }

  }
  .query040 {
    min-height: calc(100vh - 1px);
    margin: 0 auto;
    width: 1206px;
    /*position: relative;*/
    padding-top: 1px;
    box-sizing: border-box;
    /*background: #fff;*/
    /*top: -50px;*/
    .Search_title {
      padding: 34px 0 0 29px!important;
      box-sizing: border-box;
      height: 116px;
      margin: 81px auto 0!important;
      background: #ffffff;
      p:nth-child(1) {
        font-size: 19.163px;
        color: rgb(51, 51, 51);
      }

      p:nth-child(2) {
        font-size: 15.33px;
        color: rgb(102, 102, 102);
        margin-top: 16px;
      }
    }

    .pay_main{
      background: #ffffff;
      padding: 50px;
      margin-bottom: 25px;
      margin-top: 6px;
    }

    .input_list_wrap {
      .fish_bj {
        width: 131px;
        margin: 150px auto -150px;
        img {
          display: block;
          width: 100%;
        }
      }
    }
    .dib-vam-fz0 {
      font-size: 0;
      & > * {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
      }
    }
    .vh {
      visibility: hidden;
    }
    &.have-result {
      background: #f3f5fa;
      padding-top: 170px;
      box-sizing: border-box;
      .input_list_wrap {
        background: #fff;
        border: 1px solid #dbdee5;
        top: 0;
        position: fixed;
        width: 100%;
        z-index: 2;
        margin-top: -106px;
        height: 268px;
        .query-box {
          height: 46px;
          margin-bottom: 0;
        }
        .vh {
          visibility: inherit;
        }
      }
    }
    .blank {
      height: 5px;
      background-color: #f3f5fa;
    }

    .query-box {
      height: 100px;
      /*background: #ffffff;*/
      margin-bottom: 12px;
      &.vertical-horizon-center {
        height: 60px;
        margin-bottom: auto;
        .top {
          height: 61px;
        }
      }
      .query-inner {
        width: 832px;
        margin: 0 auto;
      }
      .top {
        height: 44px;
        text-align: center;
        position: relative;
        .announcement {
          position: absolute;
          bottom: 0;
          left: 0;
          .icon-warning-circle {
            color: @blue;
            font-size: 16px;
            margin-right: 7px;
          }
          span {
            font-size: 12px;
            letter-spacing: 2px;
            color: #b7b6b6;
          }
        }
        .river-horse-courtyard {
          width: 93px;
          height: 86px;
          margin: 0 auto;
          position: relative;
          padding-bottom: 20px;
          box-sizing: border-box;
          .river-horse-home {
            width: 100%;
            height: 100%;
            opacity: 0;
            img {
              width: 100%;
              height: 100%;
            }
            animation: riverHorse_query 1s 0.7s linear forwards;
          }
        }
      }
      // .search-input-box {
      //   position: relative;
      //   width: 832px;
      //   height: 64px;
      //   border-radius: 3px;
      //   background-color: #ffffff;
      //   box-shadow: 3.9px 4.5px 36px 0px rgba(153, 165, 199, 0.3);
      //   border-radius: 5px;
      //   font-size: 0;
      //   & > * {
      //     height: 64px;
      //     line-height: 64px;
      //   }
      //   .search-bg {
      //     position: absolute;
      //     width: 100%;
      //     height: 100%;
      //     top: 0;
      //     left: 0;
      //     z-index: -1;
      //   }
      //   .category {
      //     width: 120px;
      //     text-align: right;
      //     font-size: 14px;
      //     letter-spacing: 1px;
      //     color: #333333;
      //     cursor: pointer;
      //     margin-left: 5px;
      //   }
      //   .el-input-long-transparent {
      //     width: 550px; //如果要分类,则改为610
      //     margin-left: 20px;
      //     font-size: 16px;
      //   }
      //   .camera2 {
      //     margin-right: 26px;
      //     font-size: 0;
      //     .el-upload {
      //       width: 38px;
      //       height: 64px;
      //       line-height: 64px;
      //       vertical-align: top;
      //       .icon-xiangji {
      //         font-size: 32px;
      //         color: #767676;
      //         &:hover {
      //           color: @blue;
      //         }
      //         cursor: pointer;
      //         /*animation: camera_query 0.5s 0.2s linear forwards;*/
      //       }
      //       .camera-box {
      //         width: 38px;
      //         height: 100%;
      //         // animation: cameraImg 0.5s 0.2s linear forwards;
      //         overflow: hidden;
      //         &:hover {
      //           .camera-btn1 {
      //             display: none;
      //           }
      //           .camera-btn2 {
      //             display: inline;
      //           }
      //         }
      //         .camera-btn {
      //           width: 26px;
      //           vertical-align: middle;
      //         }
      //         .camera-btn1 {
      //           display: inline;
      //         }
      //         .camera-btn2 {
      //           display: none;
      //         }
      //       }
      //     }
      //   }
      //   .query-btn {
      //     cursor: pointer;
      //     letter-spacing: 0px;
      //     color: #333333;
      //     text-align: center;
      //     position: relative;
      //     font-size: 0;
      //     /*   background: gainsboro; /*   background: gainsboro; /*   background: gainsboro; /*   background: gainsboro;
      //          opacity: 0.5;*/
      //     // &::before {
      //     //   position: absolute;
      //     //   left: -2px;
      //     //   top: 50%;
      //     //   margin-top: -19px;
      //     //   content: "";
      //     //   width: 2px;
      //     //   height: 39px;
      //     //   background-color: #9ba3aa;
      //     // }
      //     &:hover {
      //       color: #ffffff;
      //       .search-btn1 {
      //         display: none;
      //       }
      //       .search-btn2 {
      //         display: inline;
      //       }
      //     }
      //     .search-btn {
      //       width: 34px;
      //       text-align: center;
      //       vertical-align: middle;
      //     }
      //     .search-btn1 {
      //       display: inline;
      //     }
      //     .search-btn2 {
      //       display: none;
      //     }
      //   }
      // }

      .input-single {
        width: 922px;
        height: 46px;
        border-radius: 5px;
        border: solid 1px #c2c6d0;
        display: flex;
        margin: 200px auto 0;
        position: relative;
        .category {
          width: 101px;
          text-align: right;
          font-size: 14px;
          letter-spacing: 1px;
          color: #333333;
          cursor: pointer;
        }
        .input_box {
          flex: 1;
          position: relative;
          border-left: 1px solid #c2c6d0;
          .input-con {
            width: 100%;
            height: 100%;
            border: 0; // 去除未选中状态边框
            outline: none; // 去除选中状态边框
            background-color: rgba(0, 0, 0, 0); // 透明背景
            .el-input-group__prepend{
              /*display: flex;*/
              /*align-items: center;*/
              width: 100px;
              position: relative;
              background: #193dbe;
              box-sizing: border-box;

            }
            .el-icon-caret-bottom{
              -webkit-transition: -webkit-transform 0.3s ease;
              -moz-transition: -moz-transform 0.3s ease;
              transition: transform 0.3s ease;
              -webkit-transform: rotate(180deg);
              -moz-transform: rotate(180deg);
              transform: rotate(180deg);
              &.rotate{
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                transform: rotate(0deg);
              }
            }
          }
        }
        .camera2 {
          padding: 0 26px 0 11px;
          box-sizing: border-box;
          font-size: 0;
          background: #fff;
          .el-upload {
            width: 38px;
            .camera-box {
              width: 38px;
              line-height: 46px;
              overflow: hidden;
              &:hover {
                .camera-btn1 {
                  display: none;
                }
                .camera-btn2 {
                  display: inline;
                }
              }
              .camera-btn {
                width: 26px;
                vertical-align: middle;
              }
              .camera-btn1 {
                display: inline;
              }
              .camera-btn2 {
                display: none;
              }
            }
          }
        }
        .query-btn {
          cursor: pointer;
          width: 78px;
          height: 48px;
          border-radius: 0 5px 5px 0;
          background: @blue url("@{imgUrl}common/search_icon.png") no-repeat
          center;
          margin-top: -1px;
          margin-left: -2px;
          margin-right: -1px;
          &:hover {
            background: @blueHover url("@{imgUrl}common/search_icon.png")
            no-repeat center;
          }
        }
        &.borderBlue {
          border-color: @blue;
          .input_box {
            border-color: @blue;
          }
        }
      }
    }
    .vertical-horizon-center {
      margin-top: 200px;
    }
    .condtion {
      /*min-height: 243px;*/
      background: #ffffff;
      /*padding: 38px 100px 35px;*/
      padding: 32px 100px;
      box-sizing: border-box;
      .first-condtion {
        border-bottom: 1px solid #d5d4d4;
        margin-bottom: 25px;
        li {
          height: 32px;
          font-size: 16px;
          letter-spacing: 2px;
          color: #666666;
          margin-right: 70px;
          line-height: 29px;
          /*border-bottom: 3px solid transparent;*/
          cursor: pointer;
          &:hover {
            color: @blue;
          }
          &:nth-of-type(2) {
            position: relative;
            &:after {
              position: absolute;
              width: 1px;
              height: 33px;
              background-color: @blue;
              content: "";
              top: -5px;
              left: 123px;
            }
          }
          position: relative;
          &:before {
            position: absolute;
            width: 0;
            height: 3px;
            background: @blue;
            content: "";
            display: inline-block;
            bottom: 0;
            transition: all 0.2s linear;
          }
          &.active {
            color: @blue;
            &:before {
              position: absolute;
              width: 100%;
              height: 3px;
              background: @blue;
              content: "";
              display: inline-block;
              bottom: 0;
            }
            /*border-bottom: 3px solid @blue;*/
          }
        }
      }
      .second-conditon {
        .item {
          font-size: 14px;
          letter-spacing: 1px;
          color: #222222;
          min-height: 45px;
          .title,
          ul,
          .more {
            vertical-align: top;
          }
          position: relative;
          .title,
          .more {
            width: 112px;
            position: absolute;
            z-index: 1;
            top: 7px;
          }
          .title {
            left: 0;
          }
          .more {
            right: 0;
            color: #999999;
            text-align: center;
            cursor: pointer;
            i {
              display: inline-block;
              width: 12px;
              height: 12px;
              position: absolute;
              top: 50%;
              margin-top: -6px;
              right: 26px;
              background: url("@{imgUrl}query/down_icon.png") no-repeat;
              background-size: 100%;
              transition: all 0.5s linear;
              &.up {
                transform: rotate(-180deg);
              }
            }
            &:hover {
              color: @blue;
              i {
                background: url("@{imgUrl}query/down_active_icon.png") no-repeat;
                background-size: 100%;
              }
            }
          }
          ul {
            position: relative;
            padding-left: 112px;
            padding-right: 112px;
            padding-top: 7px;
            box-sizing: border-box;
            left: 0;
            height: 40px;
            overflow: hidden;
            &.isOpen {
              height: auto;
              overflow: visible;
            }
            li {
              margin-right: 38px;
              cursor: pointer;
              margin-bottom: 12px;
              position: relative;
              &.isCheck {
                /*color: red;*/
              }
              .check-circle {
                position: absolute;
                right: -8px;
                top: -4px;
                background: @blue;
                border-radius: 50%;
                overflow: hidden;
                text-align: center;
                width: 15px;
                height: 15px;
                line-height: 15px;
                color: #ffffff;
                z-index: 1;
                img {
                  display: block;
                  width: 100%;
                }
              }
              &.li-category {
                width: 57px;
                line-height: 30px;
                border: 1px solid #dadada;
                text-align: center;
                &:hover {
                  color: @blue;
                  border: 1px solid @blue;
                }
              }
              &.li-status {
                width: 90px;
                line-height: 30px;
                border: 1px solid #dadada;
                text-align: center;
                &:hover {
                  color: @blue;
                  border: 1px solid @blue;
                }
              }
            }
          }
        }
        .btns {
          margin-top: 28px;
          .btn {
            display: inline-block;
            cursor: pointer;
            font-size: 14px;
            letter-spacing: 1px;
            color: #454545;
            width: 90px;
            height: 32px;
            line-height: 32px;
            border-radius: 5px;
            border: solid 1px @blue;
            text-align: center;
            margin-right: 20px;
            background: @blue;
            color: #fff;
            &:nth-child(1) {
              &:hover {
                box-shadow: @boxShadow;
              }
            }
            &.active {
              color: @blue;
              background: #fff;
            }
            &:nth-child(2) {
              &:hover {
                color: #fff;
                background: @blue;
              }
            }
          }
        }
      }
    }
    .result {
      min-height: 450px;
      background: #ffffff;
      padding-bottom: 90px;
      box-sizing: border-box;
      .table-icon {
        height: 50px;
        background: white;
        padding-top: 42px;
        padding-left: 100px;
        padding-right: 100px;
        box-sizing: border-box;
        ul.switch-style {
          font-size: 0;
          margin-right: 35px;
          background-image: url("@{imgUrl}query/switch1.png");
          &.switch-bg {
            background-image: url("@{imgUrl}query/switch2.png");
          }
          background-size: 100% 100%;
          width: 220px;
          li {
            display: inline-block;
            font-size: 13px;
            width: 110px;
            height: 46px;
            color: #353535;
            /*border: solid 1px #637a91;*/
            line-height: 43px;
            text-align: center;
            cursor: pointer;
            &.style1 {
              border-radius: 3px 0 0 3px;
            }
            &.style2 {
              border-radius: 0px 3px 3px 0px;
              /*border-left: 0px;*/
            }
            & > * {
              vertical-align: middle;
            }
            span {
            }
            &.active {
              color: #4f9bfa;
            }
          }
        }
        .switch-style-container {
          .left,
          .right {
            width: 18px;
            height: 18px;
            background-image: url("@{imgUrl}query/switchbtn.png");
            cursor: pointer;
          }
          .left {
            margin-right: 44px;
            background-position: -36px 0;
            position: relative;
            &:after {
              position: absolute;
              content: "";
              width: 1px;
              height: 20px;
              background: #cecece;
              right: -25px;
              top: -1px;
            }
            &.active,
            &:hover {
              background-position: -57px 0;
            }
          }
          .right {
            background-position: 0 0;
            &.active,
            &:hover {
              background-position: -18px 0;
            }
          }
          .download_icon {
            display: inline-block;
            width: 16px;
            height: 20px;
            background: url("@{imgUrl}common/download.png") center no-repeat;
            cursor: pointer;
            background-size: 90%;
            margin-left: 30px;
            &:hover {
              background: url("@{imgUrl}common/download_hover.png") center
              no-repeat;
              background-size: 90%;
            }
          }
        }
      }
      .table {
        li {
          padding: 0 100px;
          box-sizing: border-box;
          height: 222px;
          background: #f2f4fa;
          &:nth-of-type(2n) {
            background: #ffffff;
          }
          & > * {
            vertical-align: top;
          }
          .check_ele {
            margin-top: 104px;
            margin-right: 30px;
          }
          .left {
            height: 138px;
            width: 138px;
            line-height: 138px;
            text-align: center;
            margin-top: 33px;
            margin-right: 45px;
            cursor: pointer;
            img {
              max-width: 90%;
              max-height: 90%;
              vertical-align: middle;
            }
          }
          .right {
            width: calc(100% - 227px); //138+45
            padding-top: 25px;
            box-sizing: border-box;
            line-height: 1;
            .trademark-name {
              font-size: 20px;
              letter-spacing: 2px;
              color: #222222;
              margin-bottom: 23px;
              cursor: pointer;
            }
            .details-container {
              .details-item {
                vertical-align: top;
                .small-item {
                  font-size: 0;
                  .title,
                  .text {
                    display: inline-block;
                  }
                  .title {
                    color: #999999;
                  }
                  .text {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                }
                &.a {
                  width: 20%;
                  .title {
                    width: 65px;
                  }
                  .text {
                    width: calc(100% - 65px);
                  }
                }
                &.b {
                  width: 35%;
                  .title {
                    width: 85px;
                  }
                  .text {
                    width: calc(100% - 85px);
                  }
                }
                &.c {
                  width: 30%;
                  .title {
                    width: 74px;
                  }
                  .text {
                    width: calc(100% - 74px);
                  }
                }
                &.d {
                  width: 15%;
                  .title {
                    width: 74px;
                  }
                  .text {
                    width: calc(100% - 50px);
                    font-size: 0;
                    .text-level,
                    .level-graphical {
                      display: inline-block;
                      vertical-align: top;
                    }
                    &.level-high {
                      .text-level {
                        color: #ff5a5a;
                      }
                      .level-graphical {
                        /*background: #eb5657;*/
                        background-image: url("@{imgUrl}query/level.png");
                        background-repeat: no-repeat;
                        background-position: -120px 0;
                      }
                    }
                    &.level-middle {
                      .text-level {
                        color: #fcbc41;
                      }
                      .level-graphical {
                        /*background: #fcbc41;*/
                        background-image: url("@{imgUrl}query/level.png");
                        background-repeat: no-repeat;
                        background-position: -60px 0;
                      }
                    }
                    &.level-low {
                      .text-level {
                        color: #23c994;
                      }
                      .level-graphical {
                        /*background: #23c994;*/
                        background-image: url("@{imgUrl}query/level.png");
                        background-repeat: no-repeat;
                        background-position: 0 0;
                      }
                    }
                    .text-level {
                      font-style: normal;
                      font-size: 14px;
                      letter-spacing: 1px;
                      margin-left: 10px;
                    }
                    .level-graphical {
                      width: 60px;
                      height: 8px;
                      display: inline-block;
                      margin-top: 4px;
                    }
                  }
                }
                p {
                  font-size: 0;
                  line-height: 18px;
                  .title,
                  .text {
                    vertical-align: top;
                    font-size: 14px;
                    letter-spacing: 1px;
                    color: #454545;
                  }
                  .title {
                    margin-bottom: 17px;
                  }
                  .text {
                  }
                }
              }
            }
          }
        }
      }
      .result-ico {
        font-size: 0;
        width: 1452px; //@media
        margin: 0 auto;
        li {
          width: 210px;
          height: 220px;
          border: solid 1px #c7c7c7;
          display: inline-block;
          margin-left: 15px;
          margin-right: 15px;
          margin-bottom: 30px;
          position: relative;
          cursor: pointer;
          overflow: hidden;
          &:hover {
            .see-big-img {
              display: block;
            }
          }
          .trademark-pattern {
            padding: 15px;
            box-sizing: border-box;
            height: 220-30px;
            text-align: center;
            position: relative;
            img {
              max-width: 96%;
              max-height: 96%;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
            }
          }
          .trademark-reg,
          .see-big-img {
            height: 30px;
            background-color: #616060;
            font-size: 14px;
            line-height: 30px;
            letter-spacing: 1px;
            color: #ffffff;
            text-align: center;
          }
          .trademark-reg {
            cursor: default;
          }
          .see-big-img {
            position: absolute;
            width: 100%;
            top: 0;
            display: none;
            z-index: 2;
            cursor: pointer;
          }
          .showbig {
            position: fixed;
            width: 800px;
            background: red;
            z-index: 999;
          }
        }
      }
    }
    .no-result {
      display: block;
      height: 200px;
      font-size: 0px;
      border-bottom: 3px solid transparent;
      text-align: center;
      background: #fff;
      font-size: 15px;
      color: #949aa9;
      padding: 20px 0 36px;
      box-sizing: border-box;
      i {
        display: block;
        width: 80px;
        height: 80px;
        margin: 30px auto 40px;
        img {
          width: 100%;
        }
      }
      /*margin: 0px 100px 35px;*/
      /*box-shadow: 0px 1px 12px 0px rgba(47, 47, 47, 0.12);*/
    }
  }

  .query-result-ico-popover {
    width: 650px !important;
    background-color: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(63, 63, 63, 0.32);
    border-radius: 3px;
    border: solid 1px #d7d7d7;
    .container {
      padding: 12px 20px;
      box-sizing: border-box;
      font-size: 0;
      .top {
        & > * {
          display: inline-block;
          vertical-align: top;
          font-size: 12px;
        }
        .left {
          .tuyang {
            width: 112px;
            height: 117px;
            border: solid 1px #d0d0d0;
            position: relative;
            img {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              max-width: 95%;
              max-height: 95%;
            }
          }
          .degree,
          .zan {
            margin-top: 6px;
            font-size: 0;
            & > * {
              vertical-align: middle;
              font-size: 13px;
              display: inline-block;
            }
            .title {
              width: 71px;
              font-size: 12px;
              letter-spacing: 1px;
              color: #454545;
              display: inline-block;
              word-break: normal;
              margin-right: 15px;
            }
            .text {
              font-size: 14px;
              letter-spacing: 1px;
              color: #ef6c6d;
            }
            .feedbackImgLaughone {
              height: 26px;
              width: 25px;
              cursor: pointer;
              border: 0;
              background-color: #fff;
              margin-right: 5px;
              background-size: auto;
              background-image: url("@{imgUrl}query/updown.png");
              background-position: -50px 0;
            }
            .feedbackImgCryone {
              height: 26px;
              width: 25px;
              cursor: pointer;
              border: 0;
              background-color: #fff;
              margin-right: 5px;
              background-size: auto;
              background-image: url("@{imgUrl}query/updown.png");
              background-position: 0 0;
            }
            .feedbackImgLaughtwo {
              height: 26px;
              width: 25px;
              cursor: pointer;
              border: 0;
              background-color: #fff;
              margin-right: 5px;
              background-size: auto;
              background-image: url("@{imgUrl}query/updown.png");
              background-position: -75px 0;
            }
            .feedbackImgCrytwo {
              height: 26px;
              width: 25px;
              cursor: pointer;
              border: 0;
              background-color: #fff;
              margin-right: 5px;
              background-size: auto;
              background-image: url("@{imgUrl}query/updown.png");
              background-position: -25px 0;
            }
          }
        }
        .right {
          margin-left: 10px;
          li {
            margin-bottom: 10px;
            & > * {
              display: inline-block;
              vertical-align: top;
              font-size: 12px;
            }
            .title {
              width: 94px;
              font-size: 14px;
              letter-spacing: 1px;
              color: #666666;
              word-break: normal;
              margin-right: 15px;
              /*background: red;*/
            }
            .text {
              font-size: 13px;
              letter-spacing: 1px;
              color: #333333;
              /*background: green;*/
              width: 310px;
              overflow: hidden;
              text-overflow: ellipsis;
              /*word-break:normal;*/
            }
          }
        }
      }
      .line {
        height: 1px;
        border: solid 1px #d3d3d3;
      }
      .bottom {
        text-align: center;
        .btn {
          display: inline-block;
          width: 155px;
          height: 30px;
          line-height: 30px;
          border-radius: 3px;
          border: solid 1px #eb5657;
          margin-top: 15px;
          font-size: 13px;
          letter-spacing: 1px;
          color: #eb5657;
          cursor: pointer;
          &:hover {
            background: #eb5657;
            color: #ffffff;
          }
        }
      }
    }
  }
</style>
<style scoped lang="less">
  @media screen and (max-width: 1680px) {
    /*至1680*/
    .query040 {
      .result {
        .result-ico {
          width: 1210px;
          /*background: green;*/
          ul {
            li {
              width: 180px;
              height: 190px;
              margin-left: 10px;
              margin-right: 10px;
              .trademark-pattern {
                height: 190-30px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1500px) {
    /*至1366*/
    .query040 {
      .result {
        /*表格版样式*/
        ul.table {
          li {
            .right {
              .details-container {
                .d {
                  /*background: orange;*/
                  .small-item {
                    /*background: yellow;*/
                    .title {
                      /*background: greenyellow;*/
                      width: 50px;
                    }
                    .text {
                      /*background: blue;*/
                    }
                  }
                }
              }
            }
          }
        }
        /*图标版样式*/
        ul.result-ico {
          width: 1210px;
          /*background: red;*/
          ul {
            li {
              width: 180px;
              height: 190px;
              margin-left: 10px;
              margin-right: 10px;
              .trademark-pattern {
                height: 190-30px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1280px) {
    /*至1280*/
    .query040 {
      .result {
        ul.result-ico {
          width: 968px;
          /*background: yellow;*/
          ul {
            li {
              width: 180px;
              height: 190px;
              margin-left: 10px;
              margin-right: 10px;
              .trademark-pattern {
                height: 190-30px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1300px) {
    .condtion {
      .first-condtion {
        li {
          margin-right: 50px;
        }
      }
    }
  }
</style>
<style lang="less">
  .query040 {
    .input-con input.el-input__inner {
      background: #fff;
      border-radius: inherit;
      border: 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: inline-block;
      font-size: inherit;
      line-height: 1;
      outline: 0;
      padding: 0 15px;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      color: inherit;
    }
    .category .el-input {
      height: 46px;
    }
    .category input.el-input__inner {
      width: 100%;
      height: 100%;
      background: #e1e7fb;
      border: none;
      border-radius: 4px 0 0 4px;
    }
    .el-upload-list {
      position: absolute;
      left: 110px;
      top: 0;
      background: #fff;
      &:focus {
        outline: none;
        border: none;
      }
    }
    .el-upload-list--picture .el-upload-list__item {
      border: none;
      border-radius: 0;
      padding: 0 0 0 90px;
      box-sizing: border-box;
      height: 34px;
      line-height: 34px;
      margin-top: 7px;
      transition: none !important;
      &:focus {
        outline: none;
        border: none;
      }
    }
    .el-upload-list--picture .el-upload-list__item-thumbnail {
      width: auto;
      height: 34px;
    }
    .el-upload-list--picture .el-upload-list__item-name {
      margin-top: 0;
      margin-left: -15px;
    }
    .el-upload-list--picture .el-upload-list__item-name i {
      font-size: 0;
    }
    .el-upload-list__item .el-icon-close {
      top: 10px;
    }
  }
  .time_confirm {
    .el-message-box__title {
      font-size: 16px;
      color: #777;
      letter-spacing: 2px;
    }
    .el-message-box__message p {
      font-size: 18px;
      color: #373a42;
      margin: 10px auto 45px;
      font-weight: bold;
      letter-spacing: 2px;
    }
    .el-message-box__btns button {
      width: 68px;
      height: 32px;
      line-height: 32px;
      padding: 0;
    }
    .el-message-box__btns button:nth-child(2) {
      margin-left: 50px;
      box-shadow: none;
      &:hover {
        box-shadow: @boxShadow;
      }
    }
  }
</style>
